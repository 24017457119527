import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, Icon, Paper, createFilterOptions, } from "@mui/material";
import {
    TypographyBodyDefault,
    TypographyBodyMedium,
    TypographyTitleGroup,
} from "components/styledComponents/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { TextFieldStyled } from "components/styledComponents/Input";
import { useAppSelector } from "hooks/store";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { useNavigate } from "react-router-dom";
import { getProjectUrl } from "utils/utils";
import { PROJECT_TYPE, ROUTE_PATH_MAP } from "utils/enums";
import OutputView from "components/OutputView";
import styles from "./globalSearch.module.css";

const filterOptions = createFilterOptions({
    // matchFrom: "start",
    stringify: (option: any) => option?.name + option?.customType,
});

const CustomPaper = (CustomPaperProps: any) => {
    return <Paper sx={{
        backgroundColor: "var(--color-palette-gray-800, #253042)",
        width: "320px",
        borderRadius: "8px",
        marginTop: "10px",
        marginLeft: "-30px",
    }} elevation={8} {...CustomPaperProps} />;
};
const OuterElementContext = React.createContext({});

const LISTBOX_PADDING = 8; // px

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}


const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});


function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        color: "#7D899C",
        fontSize: "12px",
        padding: "4px 8px",
    };
    const groupStyle = {
        ...style,
        padding: "4px 8px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <TypographyTitleGroup sx={inlineStyle} key={dataSet.key}>
                {dataSet.group}
            </TypographyTitleGroup>
        );
    }

    return (
        <div component="li" {...dataSet[0]} style={groupStyle} key={dataSet[1].id}>
            <div style={{ height: "32px", width: "32px", borderRadius: "4px", backgroundColor: "#394455", display: "flex", alignItems: "center", justifyContent: "center" }} >
                {
                    dataSet[1].OptionType === "Render" ? <OutputView output={dataSet[1]} styles={styles} /> : <></>
                }
                {
                    dataSet[1].OptionType === "Project" && dataSet[1]?.lastOutput ? <OutputView output={dataSet[1]} styles={styles} /> : <></>
                }
                {
                    dataSet[1].OptionType === "Folder" ? dataSet[1]?.lastOutput ?
                        <OutputView key={dataSet[1].lastOutput.id} output={dataSet[1].lastOutput} styles={styles} />
                        : <Icon
                            sx={{
                                color: "#f5f5f5",
                                width: "26px",
                                fontSize: 20, // confirm
                                height: "26px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {"dashboard"}
                        </Icon> : <></>
                }
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <TypographyBodyDefault sx={{ color: "white" }} >
                    {`${dataSet[1].name}`}
                </TypographyBodyDefault>
                <TypographyTitleGroup sx={{ color: "#7D899C", fontSize: "12px" }} >
                    {
                        dataSet[1].OptionType === "Render" ? `${dataSet[1].folderName} | ${dataSet[1].name} | Render` : dataSet[1].OptionType === "Project" ? `${dataSet[1].folderName} | Project` : "Folder"
                    }
                </TypographyTitleGroup>
            </div>
        </div>
    );
}

const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData: React.ReactElement[] = [];

    (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const itemCount = itemData.length;
    const itemSize = 54;

    const getChildSize = (child: React.ReactElement) => {
        if (child.hasOwnProperty("group")) {
            return 28;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
    const gridRef = useResetCache(itemCount);

    return (
        <div style={{ padding: "4px 0px" }} ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const GlobalSearch = () => {
    const [isSearchExpand, setIsSearchExpand] = useState<boolean>(false)
    const [options, setOptions] = useState<any[]>([]);
    const navigate = useNavigate()
    const sortConfig: {
        field: string | undefined;
        direction: "asc" | "desc" | undefined;
    } = { field: "createdAt", direction: "desc" };
    const sortConfigByUpdate: {
        field: string | undefined;
        direction: "asc" | "desc" | undefined;
    } = { field: "updatedAt", direction: "desc" };

    const allFolders = useAppSelector(store => store.folder.allFolders);
    const allProjects = useAppSelector(store => store.project.projects);
    const visibleOutputs = useAppSelector(store => store.output.visibleOutputs);
    const brandUsers = useAppSelector(store => store.user.brandUsers ?? {});
    const imageOutputs = useAppSelector(store => store.output.imageOutputs);
    const otherOutputs = useAppSelector(store => store.output.otherOutputs);

    useEffect(() => {
        if (allFolders && allProjects && allProjects && otherOutputs) {
            var sortedValuesFolders: any[] = [];
            var sortedValuesOutputs: any = [];
            var sortedValuesProject: any[] = [];
            var option: any[] = [];

            if (allFolders && Object.keys(allFolders).length > 0) {
                const displayFolders: any[] = [];
                for (const folder of Object.values(allFolders)) {
                    const user = brandUsers[folder.userId];
                    const projects = Object.values(allProjects)
                        .filter(
                            (project) => project.folderId === folder.id && !project.mainProjectId
                        )
                        .map((p) => p.id);
                    const outputs: any = Object.values(visibleOutputs || {}).filter(
                        (output) => output.projectId && projects.includes(output.projectId)
                    );
                    const lastOutput = [...outputs].sort((a, b) =>
                        a.createdAt > b.createdAt ? -1 : 1
                    )[0];
                    displayFolders.push({
                        ...folder,
                        id: folder.id,
                        owner: user ? user.name : "",
                        name: folder.name,
                        lastOutput: lastOutput,
                        OptionType: "Folder",
                    });
                }
                sortedValuesFolders = Object.values(displayFolders).sort((a, b) => {
                    if (sortConfig.field) {
                        const fieldA = a[sortConfig.field];
                        const fieldB = b[sortConfig.field];
                        if (!fieldA && !fieldB) return 0;
                        if (!fieldA || fieldA < fieldB) {
                            return sortConfig.direction === "asc" ? -1 : 1;
                        }
                        if (!fieldB || fieldA > fieldB) {
                            return sortConfig.direction === "asc" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }

            if (visibleOutputs && Object.keys(visibleOutputs).length > 0) {
                const displayOutputs: any = [];
                for (const output of Object.values(visibleOutputs)) {
                    const project =
                        allProjects && output.projectId ? allProjects[output.projectId] : undefined;
                    if (project) {
                        displayOutputs.push({
                            ...output,
                            name: project ? project.name : "Unknown",
                            OptionType: "Render",
                            folderName: project
                                ? project.folderId && allFolders[project.folderId]
                                    ? allFolders[project.folderId].name
                                    : "Drafts"
                                : "Unknown",
                            customType: project?.type ? project?.type?.toLowerCase().replace("_", " ") : null,
                        });
                    }
                }

                sortedValuesOutputs = displayOutputs.sort((a: any, b: any) => {
                    if (sortConfig.field) {
                        const fieldA = a[sortConfig.field];
                        const fieldB = b[sortConfig.field];
                        if (!fieldA && !fieldB) return 0;
                        if (!fieldA || fieldA < fieldB) {
                            return sortConfig.direction === "asc" ? -1 : 1;
                        }
                        if (!fieldB || fieldA > fieldB) {
                            return sortConfig.direction === "asc" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }

            if (allProjects && Object.keys(allProjects).length > 0) {
                var displayProjects: any[] = [];
                displayProjects = allProjects ? Object.values(allProjects).filter((project) => !project.mainProjectId).map(project => {
                    let outputs;
                    switch (project.type) {
                        case PROJECT_TYPE.IMAGE_SET: case PROJECT_TYPE.IMAGE: case PROJECT_TYPE.FACEBOOK_AD: case PROJECT_TYPE.IMAGE_GENERATION: case PROJECT_TYPE.IMAGE_EDITOR:
                            outputs =
                                imageOutputs && imageOutputs[project.id];
                            break;
                        default:
                            outputs = otherOutputs;// && otherOutputs[project.id];
                            break;
                    }

                    const lastOutput = outputs
                        ? [...outputs].sort((a, b) =>
                            a.createdAt > b.createdAt ? -1 : 1
                        )[0]
                        : null;

                    return ({
                        ...project,
                        lastOutput: lastOutput,
                        folderName: project
                            ? project.folderId && allFolders[project.folderId]
                                ? allFolders[project.folderId].name
                                : "Drafts"
                            : "Unknown",
                        customType: project?.type ? project?.type?.toLowerCase().replace("_", " ") : null, OptionType: "Project"
                    })
                })
                    : [];

                sortedValuesProject = displayProjects.sort((a: any, b: any) => {
                    if (sortConfigByUpdate.field) {
                        const fieldA = a[sortConfigByUpdate.field];
                        const fieldB = b[sortConfigByUpdate.field];
                        if (!fieldA && !fieldB) return 0;
                        if (!fieldA || fieldA < fieldB) {
                            return sortConfigByUpdate.direction === "asc" ? -1 : 1;
                        }
                        if (!fieldB || fieldA > fieldB) {
                            return sortConfigByUpdate.direction === "asc" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }

            option = [...sortedValuesOutputs, ...sortedValuesProject, ...sortedValuesFolders];
            setOptions(option);
        }
    }, [allFolders, visibleOutputs, allProjects, otherOutputs])

    const onSearchClick = () => {
        setIsSearchExpand(!isSearchExpand)
    }

    const onItemClick = (e: any, value: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (value.OptionType === "Project") {
            navigate(getProjectUrl(value?.id, value?.type));
        }
        if (value.OptionType === "Folder") {
            navigate(`${ROUTE_PATH_MAP.FOLDERS}/${value.id}`);
        }
        if (value.OptionType === "Render") {
            navigate("/renders", { state: { searchVal: value.name } });
        }
    }

    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: !isSearchExpand ? "center" : "flex-start",
                    width: isSearchExpand ? "330px" : "40px",
                    height: "40px",
                    borderRadius: "100px",
                    padding: "0px 12px",
                    background: "#253042",
                    cursor: "pointer",
                    transition: "width 0.4s ease-in-out",
                    gap: "0.3rem",
                    "&:hover": {
                        backgroundColor:"var(--color-palette-gray-700, #394455)",
                    },
                }}
            >
                <div style={{ cursor: "pointer", height: "22px", width: "22px" }} onClick={onSearchClick} >
                    <SearchIcon sx={{ fontSize: "22px", color: "#7D899C" }} />
                </div>
                {
                    isSearchExpand && <Autocomplete
                        popupIcon={<></>}
                        id="global-search"
                        disableListWrap
                        disableClearable
                        filterOptions={filterOptions}
                        groupBy={(option) => option?.OptionType}
                        sx={{
                            width: "100%",
                            height: "40px",
                            "& .MuiFormControl-root": {
                                height: "40px",
                            },
                            "& .MuiFormControl-root:hover": {
                                backgroundColor: "transparent",
                            },
                            "& .MuiFormControl-root .MuiInputBase-root": {
                                padding: "0px",
                                paddingRight: "10px",
                            },
                        }}
                        onChange={(e, value) => onItemClick(e, value)}
                        PaperComponent={CustomPaper}
                        getOptionLabel={(option) => option?.name}
                        options={options}
                        autoHighlight
                        ListboxComponent={ListboxComponent}
                        renderOption={(props, option, state) =>
                            [props, option, state.index] as React.ReactNode
                        }
                        renderGroup={(params) => params as any}
                        renderInput={(params) => (
                            <TextFieldStyled
                                {...params}
                                sx={{
                                    backgroundColor: "transparent",
                                }}
                                placeholder="Search in folder,project,renders, etc."
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                    // disable autocomplete and autofill
                                }}
                            />
                        )}
                        noOptionsText={<TypographyBodyMedium sx={{ color: "white" }} >No options </TypographyBodyMedium>}
                    />
                }
            </Grid>
        </>
    );
};

export default React.memo(GlobalSearch);
