import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { ROUTE_PATH_MAP } from "utils/enums";
import Navbar from "components/Navbar";
import { Grid } from "@mui/material";
import Header from "components/Header";
import ErrorBoundary from "components/ErrorBoundary";
import NotificationTable from "components/NotificationTable/NotificationTable";
const AuthRouter = lazy(() => import("containers/auth"));
const ContactUs = lazy(() => import("containers/contact-us"));
const Help = lazy(() => import("containers/help"));
const NotFound = lazy(() => import("containers/NotFound"));
const Assets = lazy(() => import("containers/assets"));
const Renders = lazy(() => import("containers/renders"));
const Folders = lazy(() => import("containers/folders"));
const InFolder = lazy(() => import("containers/folders/InFolder"));
const Home = lazy(() => import("containers/home"));
const Create = lazy(() => import("containers/create/create"));
const AiFlow = lazy(() => import("containers/create/ai-flow"));
const ImageEditor = lazy(() => import("containers/image_editor"));
const Export = lazy(() => import("containers/export"));
const Users = lazy(() => import("containers/Users"));
const Brands = lazy(() => import("containers/Brands"));

const HeaderNavbarWrapper = () => <>
  <Header />
  <Grid
    display={"flex"}
    flexDirection={"row"}
    sx={{ background: "var(--color-palette-gray-900, #1A202B)" }}
    minHeight={"calc(100vh - 72px)"}
  >
    <Navbar />
    <Grid
      margin={0}
      display={"flex"}
      flexDirection={"column"}
      minHeight={"calc(100vh - 72px)"}
      padding={"16px 28px 16px 24px"}
      width={"100%"}
      sx={{ background: "var(--color-palette-gray-900, #1A202B)" }}
      gap={2}
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>

    </Grid>
  </Grid>
</>;

const HeaderBreadcrumbsWrapper = () => <>
  <Header showSteps />
  <Grid
    display={"flex"}
    flexDirection={"row"}
    minHeight={"calc(100vh - 72px)"}
    sx={{
      background: "var(--color-palette-gray-900, #1A202B)",
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/dotted_background.png)`,
      backgroundRepeat: "repeat",
    }}
  >
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  </Grid>
</>;

const routes = [
  /* Header and Navbar */
  {
    path: "/",
    element: <HeaderNavbarWrapper />,
    children: [
      {
        path: ROUTE_PATH_MAP.ASSETS,
        element: <Assets />
      },
      {
        path: ROUTE_PATH_MAP.USERS,
        element: <Users />
      },
      {
        path: ROUTE_PATH_MAP.RENDERS,
        element: <Renders />
      },
      {
        path: ROUTE_PATH_MAP.FOLDERS,
        element: <Folders />
      },
      {
        path: `${ROUTE_PATH_MAP.FOLDERS}/:folderId`,
        element: <InFolder />
      },
      {
        path: ROUTE_PATH_MAP.HOME,
        element: <Home />
      },
      {
        path: ROUTE_PATH_MAP.CREATE,
        element: <Create />
      },
      {
        path: ROUTE_PATH_MAP.BRANDS,
        element: <Brands />
      },
      {
        path: `${ROUTE_PATH_MAP.HELP}`,
        element: <Help />,
      },
      {
        path: `${ROUTE_PATH_MAP.CONTACT_US}`,
        element: <ContactUs />,
      },
      {
        path:`${ROUTE_PATH_MAP.NOTIFICATION_TABLE}`,
        element:<NotificationTable/>
      }
    ]
  },

  /* Header, Breadcrumbs and no Navbar */
  {
    path: "/",
    element: <HeaderBreadcrumbsWrapper />,
    children: [
      {
        path: `${ROUTE_PATH_MAP.CREATE}/:route/:projectId?`,
        element: <AiFlow />
      },
      {
        path: `${ROUTE_PATH_MAP.IMAGE_EDITOR}/:projectId/export`,
        element: <Export />
      },
    ]
  },

  /* Custom Header / No Header and no Navbar */
  {
    path: `${ROUTE_PATH_MAP.IMAGE_EDITOR}/:projectId`,
    element: <ImageEditor />
  },
  {
    path: ROUTE_PATH_MAP.AUTH,
    element: <AuthRouter />
  },
  {
    path: "/404",
    element: <NotFound />
  },
  {
    path: "*",
    element: <NotFound />
  }
];

export default routes;
