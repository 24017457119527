import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import { SelectStyled } from "components/styledComponents/Input";
import { TypographyBodyDefault } from "components/styledComponents/Typography";
import React, { MouseEventHandler, useState } from "react";
import { BG_CATEGORY_OPTIONS } from "utils/enums";
import { enumToName } from "utils/utils";
import AddIcon from '@mui/icons-material/Add';


type SelectBgCategoryProps = {
    value: string,
    onChange: ((event: SelectChangeEvent<unknown>, child: React.ReactNode) => void) | undefined,
    onToggleCreateCategory?: MouseEventHandler<HTMLDivElement>,
    option: string[],
    paperStyle?: any
}

const SelectBgCategory = ({ value, onChange, onToggleCreateCategory, option = [],paperStyle }: SelectBgCategoryProps) => {



    return (
        <SelectStyled
            value={value}
            onChange={onChange}
            sx={{
                maxWidth: "300px",
            }}
            MenuProps={{
                sx: {
                    ".MuiPaper-root": {
                        bgcolor: "#1A202B",
                        overflow: "auto",
                        maxHeight: "200px",
                        ...paperStyle

                    },
                    ".MuiList-root": {
                        bgcolor: "#1A202B",
                        maxHeight: "200px",
                        svg: {
                            fill: "#7D899C",
                        },
                    },
                    ".MuiMenuItem-root": {
                        bgcolor: "#1A202B",
                        color: "#7D899C",
                        gap: "8px",
                        ":hover": {
                            bgcolor: "var(--color-palette-gray-700, #394455)",
                        },
                    },
                },
            }}
        >
            {option.map((val: string) => {
                return (
                    <MenuItem key={val} value={val}>
                        <TypographyBodyDefault>
                            {enumToName(val)}
                        </TypographyBodyDefault>
                    </MenuItem>
                );
            })}
            {/* <div>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "2px dashed var(--color-palette-gray-700, #394455)",
                        height: "50px",
                        "&:hover": {
                            backgroundColor: "#394455 !important",
                        },
                    }}
                    onClick={onToggleCreateCategory}
                >
                    <div style={{
                        height: '24px',
                        width: '24px',
                        borderRadius: '4px',
                        background: '#394455',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: "8px",

                    }} >
                        <AddIcon sx={{ color: '#7D899C', fontSize: '1rem' }} />
                    </div>
                    <MenuItem
                        sx={{
                            color: "#FFFFFF !important",
                            fontSize: "14px !important",
                            fontWeight: 700,
                            lineHeight: "22px !important",
                            padding: "0px 16px !important", // Adjusted padding to remove extra space
                            "&:hover": {
                                backgroundColor: "#394455 !important",
                            },
                        }}
                    >
                        Create Type
                    </MenuItem>
                </Box>
            </div> */}
        </SelectStyled>
    );
};

export default SelectBgCategory;
