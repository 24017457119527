import React, { useEffect, useState } from "react";
import { Grid, Icon, InputAdornment } from "@mui/material";
import { MODAL_MODE, MODEL_NAMES, PERMISSIONS, USER_GUIDE_STEP } from "utils/enums";
import { StyledChip } from "components/styledComponents/Chip";
import { PrimaryButton } from "components/styledComponents/Button";
import {
  TypographyBodyDefault,
  TypographyCTA2,
  TypographyTitle,
} from "components/styledComponents/Typography";
import { BrandkitIcon } from "components/Icons";
import StylesTab from "./Tabs/StylesTab";
import AssetModal from "components/modals/AssetModal";
import Tooltip from "components/styledComponents/Tooltip";
import AssetsTable from "components/Tables/AssetsTable";
import { useEventsProvider } from "mixPanelEvent";
import { TextFieldStyled } from "components/styledComponents/Input";
import SearchIcon from "@mui/icons-material/Search";
import Info from "components/styledComponents/Info";
import { hasRolePermission } from "utils/session";
import { useAppSelector } from "hooks/store";
import AddIcon from '@mui/icons-material/Add';

export enum BRAND_TABS {
  "TEMPLATES" = "TEMPLATES",
  "ASSETS" = "ASSETS",
  "STYLES" = "STYLES",
};
const TABS: BRAND_TABS[] = [BRAND_TABS.ASSETS, BRAND_TABS.STYLES];

const Brands = () => {
  const propertyData = useAppSelector(store => store.property.userProperties);
  const isJoyrideVisible = propertyData && propertyData.length > 0 && !propertyData.some(item => item.key === USER_GUIDE_STEP.ASSETS_DETAILS) && false
  const [currentTab, setCurrentTab] = useState<BRAND_TABS>(isJoyrideVisible ? BRAND_TABS.STYLES : BRAND_TABS.ASSETS);
  const [open, setOpen] = useState(false);
  const { onBrandAssetAdd } = useEventsProvider();
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [keyUpTimeout, setKeyUpTimeout] = useState<any>(null);
  const canCreate = hasRolePermission(MODEL_NAMES.ASSET, PERMISSIONS.CREATE);


  const handleSearch = (e: any) => {
    const { value } = e.target
    setSearch(value)
    setKeyUpTimeout(clearTimeout(keyUpTimeout));
    setKeyUpTimeout(
      setTimeout(() => {
        setDebounceSearch(value);
      }, 1000)
    );
  };

  return (
    <>
      {open && (
        <AssetModal
          modalMode={MODAL_MODE.UPLOAD}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      <Grid
        sx={{ background: "var(--color-palette-gray-900, #1A202B)" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        gap={"32px"}
      >
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          width={"100%"}
          gap={2}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Grid
              display={"flex"}
              alignItems={"center"}
              gap={1}
              width={"100%"}
            >
              <TypographyTitle> Brands</TypographyTitle>
              <Info placement="right" title="Create and manage your brand's visual library by storing your logos, colors & backgrounds securely." />
            </Grid>
            {
              currentTab === BRAND_TABS.ASSETS ? <Grid
                display={"flex"}
                alignItems={"center"}
                alignSelf={"flex-end"}
              >
                <TextFieldStyled
                  sx={{
                    "& .MuiInputBase-root .MuiInputBase-input": {
                      padding: "10px 12px 10px 0px !important"
                    },
                    background: "var(--color-palette-gray-800, #253042)",
                  }}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#AEB6C4 !important", fontSize: "1rem !important" }} />
                      </InputAdornment>
                    ),
                  }}
                  name="search"
                  id="folder-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Grid> : <></>
            }

          </Grid>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            height={"44px"}
          >

            <Grid display={"flex"} gap={0.5}>
              {TABS.map((item, index) => {
                return (
                  <StyledChip
                    key={index}
                    sx={{
                      border:
                        currentTab === item
                          ? "1px solid var(--color-palette-gray-700, #394455)"
                          : "1px solid var(--color-palette-gray-500, #7D899C)",
                      background:
                        currentTab === item
                          ? "var(--color-palette-gray-700, #394455)"
                          : "transparent",
                      "&:hover": {
                        background: currentTab === item
                          ? "var(--color-palette-gray-700, #394455)" : "transparent",
                        border: currentTab === item
                          ? "1px solid var(--color-palette-gray-700, #394455)" : "1px solid white",
                      },
                    }}
                    onClick={() => setCurrentTab(item)}
                  >
                    <Icon
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "& path": {
                          fill: currentTab === item ? "white" : "#7D899C",
                        },
                      }}
                    >
                      {BrandkitIcon}
                    </Icon>
                    <TypographyBodyDefault sx={{ color: 'white', textTransform: 'capitalize' }} >
                      {item === BRAND_TABS.ASSETS ? "Brand Assets" : "Brand Styles"}
                    </TypographyBodyDefault>
                  </StyledChip>
                )
              })}
            </Grid>
            {currentTab === BRAND_TABS.ASSETS && canCreate && <Tooltip title="Add new Asset">
              <div onClick={() => { setOpen(true); onBrandAssetAdd(); }} style={{
                display: 'flex', alignItems: 'center', height: '32px', backgroundColor: 'var(--color-palette-blue-300, #3C88FC)',
                color: 'white', borderRadius: '6px', cursor: 'pointer', width: '120px'
              }} >
                <div style={{ height: '32px', padding: '0px 8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AddIcon sx={{ fontSize: '1rem !important' }} />
                </div>
                <div style={{ height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                  <TypographyCTA2> Add Assets </TypographyCTA2>
                </div>
              </div>

            </Tooltip>}
          </Grid>
        </Grid>
        <Grid
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          width={"100%"}
        >
          {currentTab === BRAND_TABS.ASSETS && (
            <AssetsTable debounceSearch={debounceSearch} handleGetStarted={() => setOpen(true)} />
          )}
          {currentTab === BRAND_TABS.STYLES && <StylesTab />}
        </Grid>
      </Grid>
    </>
  );
};

export default Brands;
