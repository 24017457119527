import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ASSET_TYPE, SCOPE, SEVERITY } from '../utils/enums';
import { getApi, putApi, deleteApi, postApi } from '../utils/fetchUtils';
import { Asset, CallbackModelType, CallbackType, showSnackbarType } from '../utils/types';
import { AppThunk } from '../store';

const initialState = {
  allAssets: null as { [id: string]: Asset } | null,
  brandkitAssets: null as Asset[] | null,
  imageAssets: [] as Asset[] | null,
  greenScreenAssets: null as Asset[] | null,
  productAssets: [] as Asset[],
  backgroundAssets: [] as Asset[],
  backgroundDisplayAssets: [] as Asset[],
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    updateAllAssets: (state, action: PayloadAction<Asset[]>) => {
      state.allAssets = {};
      action.payload.forEach(asset => {
        if (!state.allAssets) { state.allAssets = {}; }
        state.allAssets[asset.id] = asset;
      });
    },
    updateBrandkitAssets: (state, action: PayloadAction<Asset[]>) => {
      state.brandkitAssets = action.payload;
    },
    updateImageAssets: (state, action: PayloadAction<Asset[]>) => {
      state.imageAssets = action.payload;
    },
    updateGreenScreenAssets: (state, action: PayloadAction<Asset[]>) => {
      state.greenScreenAssets = action.payload;
    },
    updateProductAssets: (state, action: PayloadAction<Asset[]>) => {
      state.productAssets = action.payload;
    },
    updateBackgroundAssets: (state, action: PayloadAction<Asset[]>) => {
      state.backgroundAssets = action.payload;
    },
    updateBackgroundDisplayAssets: (state, action: PayloadAction<Asset[]>) => {
      state.backgroundDisplayAssets = action.payload;
    },
    updateAsset: (state, action: PayloadAction<Asset>) => {
      if (!state.allAssets) state.allAssets = {};
      if (!state.brandkitAssets) state.brandkitAssets = [];
      if (!state.imageAssets) state.imageAssets = [];
      if (!state.greenScreenAssets) state.greenScreenAssets = [];

      const asset = action.payload;
      state.allAssets[asset.id] = asset;

      switch (asset.type) {
        case ASSET_TYPE.FONT:
        case ASSET_TYPE.COLOR:
        case ASSET_TYPE.LOGO:
        case ASSET_TYPE.PDF:
          state.brandkitAssets.push(asset);
          break;
        case ASSET_TYPE.IMAGE:
          state.imageAssets.push(asset);
          break;
        case ASSET_TYPE.GREEN_SCREEN:
          state.greenScreenAssets.push(asset);
          break;
        case ASSET_TYPE.PRODUCT:
          state.productAssets.push(asset);
          break;
        case ASSET_TYPE.BACKGROUND:
          state.backgroundAssets.push(asset);
          break;
        case ASSET_TYPE.BACKGROUND_DISPLAY:
          state.backgroundDisplayAssets.push(asset);
          break;
        default:
          break;
      }
    },
    removeAsset: (state, action: PayloadAction<string>) => {
      if (!state.allAssets) state.allAssets = {};
      if (!state.brandkitAssets) state.brandkitAssets = [];
      if (!state.imageAssets) state.imageAssets = [];
      if (!state.greenScreenAssets) state.greenScreenAssets = [];

      const asset = state.allAssets[action.payload];
      delete state.allAssets[action.payload];

      switch (asset.type) {
        case ASSET_TYPE.FONT:
        case ASSET_TYPE.COLOR:
        case ASSET_TYPE.LOGO:
        case ASSET_TYPE.PDF:
          state.brandkitAssets = state.brandkitAssets.filter(val => val.id !== asset.id)
          break;
        case ASSET_TYPE.IMAGE:
          state.imageAssets = state.imageAssets.filter(val => val.id !== asset.id)
          break;
        case ASSET_TYPE.GREEN_SCREEN:
          state.greenScreenAssets = state.greenScreenAssets.filter(val => val.id !== asset.id)
          break;
        case ASSET_TYPE.PRODUCT:
          state.productAssets = state.productAssets.filter(val => val.id !== asset.id)
          break;
        case ASSET_TYPE.BACKGROUND:
          state.backgroundAssets = state.backgroundAssets.filter(val => val.id !== asset.id)
          break;
        case ASSET_TYPE.BACKGROUND_DISPLAY:
          state.backgroundDisplayAssets = state.backgroundDisplayAssets.filter(val => val.id !== asset.id)
          break;
        default:
          break;
      }
    },
  },
});

export default assetSlice.reducer;

export async function loadFont(fontFamily: string, url: string) {
  try {
    const font = new FontFace(`CustomFont_${fontFamily}`, `url('${url}')`);
    await font.load();
    document.fonts.add(font);
  } catch (e) {
    console.log("loadFont error", e)
  }
}

export async function loadGlobalFont(fontFamily: string, url: string) {
  try {
    return new Promise<void>(resolve => {
      const link = document.createElement('link');
      link.href = url;
      link.rel = 'stylesheet';
  
      document.head.appendChild(link);
      link.onload = () => {resolve()}
    })
  } catch (e) {
    console.log("loadGFont error", e)
  }
}

export const getAllAssets = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const allAssets: Asset[] = await getApi("asset");
    dispatch(assetSlice.actions.updateAllAssets(allAssets));
    dispatch(assetSlice.actions.updateBrandkitAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.FONT || asset.type === ASSET_TYPE.COLOR || asset.type === ASSET_TYPE.LOGO || asset.type === ASSET_TYPE.PDF)));
    dispatch(assetSlice.actions.updateImageAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.IMAGE)));
    dispatch(assetSlice.actions.updateGreenScreenAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.GREEN_SCREEN)));
    dispatch(assetSlice.actions.updateProductAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.PRODUCT)));
    dispatch(assetSlice.actions.updateBackgroundAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.BACKGROUND)));
    dispatch(assetSlice.actions.updateBackgroundDisplayAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.BACKGROUND_DISPLAY)));
    await Promise.all(allAssets.filter(asset => asset.type === ASSET_TYPE.FONT).map(asset => loadFont(asset.id, asset.url)));
    callback?.();
  }

export const updateAssetById = (id: string, payload: any, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Asset updated.", SEVERITY.SUCCESS);
      callback?.();
    }

    const updatedAsset: Asset = await postApi(`asset/${id}`, payload);
    dispatch(assetSlice.actions.updateAsset(updatedAsset));
    cb();
  }

export const deleteAssetById = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Asset deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    await deleteApi(`asset/${id}`);
    dispatch(assetSlice.actions.removeAsset(id));
    cb();
  }

export const deleteAssetsById = (ids: string[], showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Asset deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    for (const id of ids) {
      await deleteApi(`asset/${id}`);
      dispatch(assetSlice.actions.removeAsset(id));
    }

    cb();
  }

export const uploadAsset = (payload: { file: File|Blob, name: string, type: string, scope: string, tags: string[], mimeType?: string, removeBackground?: boolean, metadata?: any, thumbnail?: File }, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Asset>): AppThunk =>
  async dispatch => {
    const cb = (asset: Asset) => {
      showSnackbar?.("Asset uploaded.", SEVERITY.SUCCESS);
      callback?.(asset);
    }

    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("name", payload.name);
    formData.append("type", payload.type);
    formData.append("scope", payload.scope);

    if (payload.mimeType) {
      formData.append("mime_type", payload.mimeType);
    }

    if (payload.removeBackground !== undefined) {
      formData.append("remove_background", JSON.stringify(payload.removeBackground));
    }

    if (payload.metadata) {
      formData.append("metadata", JSON.stringify(payload.metadata));
    }

    if (payload.thumbnail) {
      formData.append("thumbnail", payload.thumbnail);
    }

    for (const tag of payload.tags) {
      formData.append("tags[]", tag);
    }

    const uploadedAsset: Asset = await putApi("asset", formData);

    if (uploadedAsset.type === ASSET_TYPE.FONT) {
      await loadFont(uploadedAsset.id, uploadedAsset.url);
    }

    if (ASSET_TYPE.HIDDEN !== uploadedAsset.type) dispatch(assetSlice.actions.updateAsset(uploadedAsset));
    cb(uploadedAsset);
  }

export const uploadBrandGuidelineAssets = (payload: { file: File, name: string }, callback?: CallbackModelType<Asset>): AppThunk =>
  async dispatch => {

    const formData = new FormData();
    formData.append("file", payload.file);
    const data = await postApi("ai/brand-guidelines", formData);

    if (data && data.guidelines) {
      dispatch(uploadAsset({
        file: payload.file,
        name: payload.name.trim(),
        type: ASSET_TYPE.PDF,
        scope: SCOPE.BRAND,
        tags: [],
        metadata: data,
      }, undefined, callback));
    }
  }
