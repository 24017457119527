import React, { useContext, useEffect, useState } from 'react';
import { Grid, Icon } from '@mui/material';
import { SnackbarContext } from 'components/Snackbar';
import { AsyncButton, PrimaryButton } from 'components/styledComponents/Button';
import { TextFieldStyled } from 'components/styledComponents/Input';
import { TypographyBodyLarge } from 'components/styledComponents/Typography';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { createBrandProperty, updateBrandProperty } from 'store/property';
import { SEVERITY } from 'utils/enums';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { urlExist } from 'utils/fetchUtils';
import { SaveIcon } from 'components/Icons';
import Tooltip from 'components/styledComponents/Tooltip';
import { useEventsProvider } from 'mixPanelEvent';

const BrandWebsite = () => {
    const dispatch = useAppDispatch();
    const FieldLimit = 5;
    const { showSnackbar } = useContext(SnackbarContext);
    const propertyData = useAppSelector(store => store.property.brandProperties);
    const brandWebsites = propertyData?.find(item => item.key === "websites");
    const { addBrandWebsite, removerBrandWebsite, SaveBrandWebsite } = useEventsProvider();

    const [websiteUrls, setWebsiteUrls] = useState<string[]>([""]);

    useEffect(() => {
        if (brandWebsites) {
            setWebsiteUrls(JSON.parse(brandWebsites.value));
        }
    }, [brandWebsites]);

    const onSuccess = (message: string) => {
        showSnackbar(message, SEVERITY.SUCCESS);
    }

    const handleOnAddField = () => {
        if (websiteUrls.length <= FieldLimit) {
            const websiteUrlsLocal = [...websiteUrls];
            websiteUrlsLocal.push("");
            setWebsiteUrls(websiteUrlsLocal);
            addBrandWebsite()
        } else {
            showSnackbar("Can not add more then 5 fields", SEVERITY.WARNING);
        }
    }

    const handleOnRemoveField = (index: number) => {
        const websiteUrlsLocal = [...websiteUrls]
        websiteUrlsLocal.splice(index, 1)
        setWebsiteUrls(websiteUrlsLocal);
        removerBrandWebsite()
    }

    const onSaveUrl = async () => {
        for await (const url of websiteUrls) {
            if (!url) {
                throw "Enter URL";
            }

            const value = url.startsWith("http") ? url : "https://" + url;

            const res = await urlExist(value);
            if (!res) {
                throw `${url} is an invalid url or url does not exist.`;
            }
        }

        if (brandWebsites) {
            return new Promise<void>(resolve => dispatch(updateBrandProperty("websites", JSON.stringify(websiteUrls), undefined, () => {
                onSuccess("Website link added");
                SaveBrandWebsite()
                resolve();
            })));
        } else {
            return new Promise<void>(resolve => dispatch(createBrandProperty("websites", JSON.stringify(websiteUrls), undefined, () => {
                onSuccess("Website link added");
                SaveBrandWebsite()
                resolve();
            })));
        }
    };

    return (
        <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            width={"100%"}
            gap={2}
        >
            <TypographyBodyLarge
                sx={{ color: "var(--color-palette-white, #FFF)" }}
            >
                Brand Website
            </TypographyBodyLarge>
            <Grid
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                gap={2}
                flexWrap={"wrap"}
            >
                {websiteUrls.map((item, index) => {
                    return (
                        <Grid
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                            id="brandWebsite"
                            sx={{ cursor: "pointer" }}

                        >
                            <TextFieldStyled
                                fullWidth
                                name="url"
                                placeholder="Enter Url"
                                value={item}
                                autoComplete='off'
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const websiteUrlsLocal = [...websiteUrls];
                                    websiteUrlsLocal[index] = value;
                                    setWebsiteUrls(websiteUrlsLocal);
                                }}
                                sx={{
                                    textAlign: "left",
                                    width: '250px',
                                    backgroundColor: "var(--color-palette-gray-700, #394455)",
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        color: "gray",
                                        WebkitTextFillColor: 'gray'
                                    },
                                }}
                            />
                            {
                                ((index === (websiteUrls.length - 1) && websiteUrls.length > 1 && websiteUrls.length !== FieldLimit) || (index === 0 && websiteUrls.length === 1)) ?
                                    <Tooltip title='Add' >
                                        <PrimaryButton disableRipple onClick={handleOnAddField} sx={{
                                            height: '35px', borderRadius: '50%', padding: '0px !important', minWidth: '35px !important', backgroundColor: 'transparent', ':hover': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}>
                                             <TypographyBodyLarge sx={{
                                               "&:hover": {
                                                    transform: "scale(1.2)",
                                                }
                                            }} >
                                            <AddIcon sx={{ color: 'white' }} />

                                            </TypographyBodyLarge>
                                        </PrimaryButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Remove' >
                                        <PrimaryButton disableRipple onClick={() => { handleOnRemoveField(index) }} sx={{
                                            height: '35px', backgroundColor: 'transparent', ':hover': {
                                                backgroundColor: 'transparent',

                                            }, borderRadius: '50%', padding: '0px !important', minWidth: '35px !important'
                                        }}>
                                            <TypographyBodyLarge sx={{
                                               "&:hover": {
                                                    transform: "scale(1.2)",
                                                }
                                            }} >
                                                <RemoveIcon sx={{ color: 'white' }} />

                                            </TypographyBodyLarge>
                                        </PrimaryButton>
                                    </Tooltip>
                            }
                            {
                                index === (websiteUrls.length - 1) ?
                                    <>
                                        <Tooltip title='Save' >
                                            <div

                                            >
                                                <AsyncButton disableRipple onClick={onSaveUrl} sx={{
                                                    padding: '10px 4px', height: '42px', minWidth: '0px', backgroundColor: 'transparent', ':hover': {
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}>
                                                    <TypographyBodyLarge sx={{
                                                        display: 'flex', gap: '4px', color: 'white', "&:hover": {
                                                            transform: "scale(1.2)",
                                                        }
                                                    }} >
                                                        <Icon>
                                                            {SaveIcon}
                                                        </Icon>
                                                        {/* Save */}
                                                    </TypographyBodyLarge>
                                                </AsyncButton>
                                            </div>

                                        </Tooltip>
                                    </>
                                    : <> </>
                            }
                        </Grid>
                    )
                })
                }
            </Grid>
        </Grid>
    )

}

export default React.memo(BrandWebsite);
