import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Chip,
  Dialog,
  Grid,
  Input,
  MenuItem,
  capitalize,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  TypographyBodyDefault,
  TypographyBodyLarge,
  TypographyBodySmallNormal,
} from "components/styledComponents/Typography";
import { AsyncButton } from "components/styledComponents/Button";
import { CheckboxStyled, SelectStyled, TextFieldStyled } from "components/styledComponents/Input";
import styles from "./assetModal.module.css";
import { Asset, PromptAdvanced } from "utils/types";
import getAssetViewComponent from "components/AssetView";
import upload from "assets/upload.svg";
import {
  ASSET_TYPE,
  ASSET_TYPES,
  BG_CATEGORY,
  BG_CATEGORY_OPTIONS,
  BG_CATEGORY_OPTIONS_SUPER_ADMIN,
  MODAL_MODE,
  SCOPE,
  SUPERADMIN_ASSET_TYPES,
} from "utils/enums";
import { SnackbarContext } from "components/Snackbar";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  updateAssetById,
  uploadAsset,
} from "store/asset";
import { HexColorPicker } from "react-colorful";
import { enumToName } from "utils/utils";
import { startCase, toLower } from "lodash";
import { getAdvancedOptionsUI } from "components/Prompt/PromptAdvanceOptions";
import SelectBgCategory from "components/PromptLayout/PromptLeftPanel/SelectBgCategory";

const DEFAULT_ADV_OPTION_VALUES = {
  seed: 439844,
  dilation: {
    lower: 10,
    upper: 20,
  },
  depth: 1.0,
  canny: 0.3,
  normal: 0.0,
};

const autofillName = (input: string) => {
  const name = input
    .split(".")[0] // removing extension
    .replaceAll(/[_-]+/g, " ") // replacing _ and - char with space
    .replaceAll(/[^a-zA-Z0-9 ]+/g, ""); // removing all other char other than a-zA-Z0-9 and space

  return capitalize(name);
};

const image = ["jpg", "jpeg", "png"];
const video = ["mp4"];
const font = ["ttf", "otf"];

const tempAsset: Asset = {
  id: "",
  name: "",
  url: upload,
  type: ASSET_TYPE.IMAGE,
  mimeType: "image/svg",
  scope: SCOPE.BRAND,
  tags: [],
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
  userId: "",
  brandId: "",
  fileName: "",
  thumbnail: null,
  metadata: {},
};

const THUMBNAIL_ASSET_TYPES: ASSET_TYPE[] = [
];

type ModalProps = UIModalProps & {
  open: boolean;
};

type UIModalProps = {
  onClose: (asset?: Asset) => void;
  asset?: Asset;
  uploadAssetType?: ASSET_TYPE;
  tags?: string[];
  scope?: SCOPE;
  isBrandkit?: boolean;
  modalMode: MODAL_MODE;
};

const getAssetType = (fileName: string) => {
  const splits = fileName.split(".");
  const fileExt = splits[splits.length - 1];
  if (fileExt) {
    if (image.includes(fileExt)) return ASSET_TYPE.IMAGE;
    if (video.includes(fileExt)) return ASSET_TYPE.VIDEO;
    if (font.includes(fileExt)) return ASSET_TYPE.FONT;
  }
  return ASSET_TYPE.HIDDEN;
};

export const AssetModalUI = (props: UIModalProps) => {
  const {
    onClose,
    asset = { ...tempAsset },
    uploadAssetType,
    tags: initTags,
    scope,
    isBrandkit,
    modalMode,
  } = props;
  const dispatch = useAppDispatch();
  const thumbnailAsset: Asset = { ...tempAsset };
  const coordinateAsset: Asset = { ...tempAsset };
  const [dragActive, setDragActive] = useState(false);
  const [fileData, setFileData] = useState<FileList>(new DataTransfer().files);
  const [thumbnailFileData, setThumbnailFileData] = useState<FileList>(
    new DataTransfer().files
  );
  const [coordinateFileData, setCoordinateFileData] = useState<FileList>(
    new DataTransfer().files
  );
  const [displayAsset, setDisplayAsset] = useState<JSX.Element>(
    getAssetViewComponent(asset, styles, false)
  );
  const [uploadText, setUploadText] = useState("Upload Files");
  const [thumbnailFileUploadText, setThumbnailFileUploadText] = useState(
    modalMode !== MODAL_MODE.UPLOAD
      ? ""
      : "Drag and drop your thumbnail file here"
  );
  const [thumbnailAssetDisplay, setThumbnailAssetDisplay] =
    useState<JSX.Element>(getAssetViewComponent(thumbnailAsset, styles, false));
  const [coordinateFileUploadText, setCoordinateFileUploadText] = useState(
    modalMode !== MODAL_MODE.UPLOAD
      ? ""
      : "Drag and drop your coordinate file here"
  );
  const [coordinateAssetDisplay, setCoordinateAssetDisplay] =
    useState<JSX.Element>(
      getAssetViewComponent(coordinateAsset, styles, false)
    );
  const [assetType, setAssetType] = useState(uploadAssetType ?? ASSET_TYPES[0]);
  const [name, setName] = useState("");
  const [tags, setTags] = useState<string[]>(initTags ?? []);
  const [color, setColor] = useState("#000000");
  const [tag, setTag] = useState<string>("");
  const [removeBackground, setRemoveBackground] = useState(modalMode === MODAL_MODE.UPLOAD);
  const backgroundAssets = useAppSelector(store => store.asset.backgroundAssets);
  const [prompt, setPrompt] = useState("kept on sand, focus on product, forest in the background, detailed, reflections and shadows, ");
  const [backgroundAsset, setBackgroundAsset] = useState("none");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");

  const [advanced, setAdvanced] = useState<PromptAdvanced>(DEFAULT_ADV_OPTION_VALUES);
  const isSuperAdmin = useAppSelector(store => store.user.isSuperAdmin);
  const currentBrandId = useAppSelector(store => store.user.currentBrandId);
  const bgCategoryOptions = isSuperAdmin ? [...BG_CATEGORY_OPTIONS_SUPER_ADMIN] : [...BG_CATEGORY_OPTIONS]
  const [bgCategory, setBgCategory] = useState<string>(isSuperAdmin ? bgCategoryOptions[0] : BG_CATEGORY.UPLOADED);
  const { showSnackbar } = useContext(SnackbarContext);
  const [scopeType, setScopeType] = useState(scope ?? (isSuperAdmin && !currentBrandId ? SCOPE.GLOBAL : SCOPE.BRAND));

  const isColorValid = (color: string) => {
    const regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    return regex.test(color);
  };

  useEffect(() => {
    if (modalMode !== MODAL_MODE.UPLOAD) {
      setName(asset.name);
      setAssetType(asset.type);
      setTags(asset.tags);
      setScopeType(asset.scope)
      if (asset.type === ASSET_TYPE.COLOR) {
        setColor(asset.mimeType);
      }
      if (asset.type === ASSET_TYPE.BACKGROUND_DISPLAY) {
        setAdvanced(asset.metadata.advanced);
        setPrompt(asset.metadata.prompt);
        setBackgroundAsset(asset.metadata.backgroundAsset);
        setBgCategory(asset.metadata.backgroundCategory)
      }
      if (asset.type === ASSET_TYPE.BACKGROUND) {
        setBgCategory(asset.metadata.backgroundCategory)
      }
      if (asset.type === ASSET_TYPE.PRODUCT) {
        setDescription(asset.metadata.description ?? "");
        setProductType(asset.metadata.product_type ?? "");
      }
    }
  }, []);

  // handle drag events
  const handleDrag = function (e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const onFileChange = (
    e: React.DragEvent | React.ChangeEvent,
    files: FileList | null,
    fileType: "coordinate" | "asset" | "thumbnail"
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (files && files[0]) {
      // at least one file has been dropped so do something
      if (fileType === "asset") {
        setFileData(files);
        const newAssetType = uploadAssetType ?? assetType !== ASSET_TYPE.IMAGE ? assetType : getAssetType(files[0].name);

        setAssetType(newAssetType);
        setUploadText(files[0].name);

        asset.url = URL.createObjectURL(files[0]);
        asset.mimeType = files[0].type;
        asset.type = newAssetType;
        asset.name = files[0].name;

        setDisplayAsset(getAssetViewComponent(asset, styles, false));
        if (name === "") setName(autofillName(files[0].name));
      } else if (fileType === "coordinate") {
        setCoordinateFileData(files);
        setCoordinateFileUploadText(files[0].name);

        coordinateAsset.url = URL.createObjectURL(files[0]);
        coordinateAsset.mimeType = files[0].type;
        coordinateAsset.type = ASSET_TYPE.TEXT;
        coordinateAsset.name = autofillName(files[0].name);

        setCoordinateAssetDisplay(
          getAssetViewComponent(coordinateAsset, styles, false)
        );
      } else if (fileType === "thumbnail") {
        setThumbnailFileData(files);
        setThumbnailFileUploadText(files[0].name);

        thumbnailAsset.url = URL.createObjectURL(files[0]);
        thumbnailAsset.mimeType = files[0].type;
        thumbnailAsset.type = getAssetType(files[0].name);
        thumbnailAsset.name = files[0].name;

        setThumbnailAssetDisplay(
          getAssetViewComponent(thumbnailAsset, styles, false)
        );
      }
    }
  };

  // global asset can be made brand or vice versa
  // brandkit asset can't be made global or vice versa
  const handleUpload = async () => {
    if (assetType === ASSET_TYPE.COLOR && !isColorValid(color)) {
      throw "Invalid Color Hex Code.";
    }
    if (!name.match(/^[a-zA-Z\d ]+$/)) {
      throw "Invalid Asset name.";
    }
    if (!(assetType === ASSET_TYPE.COLOR) && !fileData?.length) {
      throw "No file selected.";
    }
    if (
      THUMBNAIL_ASSET_TYPES.includes(assetType) &&
      !thumbnailFileData?.length
    ) {
      throw "No thumbnail file selected.";
    }
    if (assetType === ASSET_TYPE.GREEN_SCREEN && !coordinateFileData?.length) {
      throw "No coordinate file selected.";
    }
    if (assetType === ASSET_TYPE.BACKGROUND_DISPLAY && prompt.trim() === "") {
      throw "Prompt is empty.";
    }
    if (assetType === ASSET_TYPE.PRODUCT && description.trim() === "") {
      throw "Product description is empty.";
    }

    const newTags = [...tags];
    if (tag.trim() !== "" && !tags.includes(tag.trim())) {
      newTags.push(tag.trim());
    }
    setTags(newTags);
    setTag("");

    if (assetType === ASSET_TYPE.PRODUCT && newTags.length === 0) {
      throw "No tags.";
    }

    let metadata: any = {};
    if (assetType === ASSET_TYPE.BACKGROUND_DISPLAY) {
      metadata.backgroundCategory = bgCategory;
      if (backgroundAsset === "none") {
        const uploadedBackgroundAsset = await new Promise<Asset>(resolve => dispatch(uploadAsset({
          file: fileData[0],
          name: `${name.trim()}`,
          type: ASSET_TYPE.BACKGROUND,
          scope: scopeType,
          tags: newTags,
          metadata
        }, undefined, resolve)));
        metadata.backgroundAsset = uploadedBackgroundAsset.id;
      } else {
        metadata.backgroundAsset = backgroundAsset;
      }
      metadata = { advanced, prompt };
    }
    if (assetType === ASSET_TYPE.GREEN_SCREEN) {
      const coordinatesAsset = await new Promise<Asset>(resolve => dispatch(uploadAsset({
        file: coordinateFileData[0],
        name: `${name.trim()} Coordinates`,
        type: ASSET_TYPE.HIDDEN,
        scope: scopeType,
        tags: newTags,
      }, undefined, resolve)));
      metadata.coordinatesAsset = coordinatesAsset.id;
    }
    if (assetType === ASSET_TYPE.PRODUCT) {
      metadata.product_type = productType.trim();
      metadata.description = description.trim();
    }
    if (assetType === ASSET_TYPE.BACKGROUND) {
      metadata.backgroundCategory = bgCategory
    }

    const file = fileData[0];
    const payload: any = {
      file: file,
      name: name.trim(),
      type: assetType,
      scope: scopeType,
      tags: newTags,
      removeBackground,
      metadata,
    };

    if (assetType === ASSET_TYPE.COLOR) {
      payload.mimeType = color;
    }
    if (assetType === ASSET_TYPE.FONT) {
      payload.mimeType = `font/${file.name.split(".")[1]}`;
    }
    if (THUMBNAIL_ASSET_TYPES.includes(assetType)) {
      payload.thumbnail = thumbnailFileData[0];
    }

    return new Promise<void>(resolve => dispatch(uploadAsset(payload, showSnackbar, (asset) => {
      onSuccess(asset);
      resolve();
    })));
  };

  const onSuccess = (asset?: Asset) => {
    onClose(asset);
  };

  const handleUpdate = async () => {
    if (!name.match(/^[a-zA-Z\d ]+$/)) {
      throw "Invalid Asset name.";
    }
    if (assetType === ASSET_TYPE.COLOR && !isColorValid(color)) {
      throw "Invalid Color Hex Code.";
    }
    if (assetType === ASSET_TYPE.BACKGROUND_DISPLAY && prompt.trim() === "") {
      throw "Prompt is empty.";
    }
    if (assetType === ASSET_TYPE.PRODUCT && description.trim() === "") {
      throw "Product description is empty.";
    }

    const newTags = [...tags];
    if (tag.trim() !== "" && !tags.includes(tag.trim())) {
      newTags.push(tag.trim());
    }
    setTags(newTags);
    setTag("");

    if (assetType === ASSET_TYPE.PRODUCT && newTags.length === 0) {
      throw "No tags.";
    }

    const payload: any = {
      name: name.trim(),
      tags: newTags,
      removeBackground,
      scope: scopeType,
    };

    if (THUMBNAIL_ASSET_TYPES.includes(assetType)) {
      payload.thumbnail = thumbnailFileData[0];
    }
    if (assetType === ASSET_TYPE.COLOR) {
      payload.mime_type = color;
    }
    if (assetType === ASSET_TYPE.BACKGROUND_DISPLAY) {
      payload.metadata = { advanced, prompt, backgroundAsset, backgroundCategory: bgCategory };
    }
    if (assetType === ASSET_TYPE.PRODUCT) {
      payload.metadata = {
        product_type: productType.trim(),
        description: description.trim(),
      }
    }

    return new Promise<void>(resolve => dispatch(updateAssetById(asset.id, payload, showSnackbar, () => {
      onSuccess();
      resolve();
    })));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Backspace") {
      const value = event.target.value;
      if (tags.length > 0 && !value) {
        const newTags = [...tags];

        newTags.pop();
        setTags([...newTags]);
      }
      event.stopPropagation();
    }
    if (event.key === "Enter") {
      if (tag.trim() !== "" && !tags.includes(tag.trim())) {
        setTags([...tags, tag.trim()]);
        setTag("");
      }
    }
    if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      event.stopPropagation();
    }
  };

  const getFileTypeText = (assetType: ASSET_TYPE) => {
    if (
      ASSET_TYPE.IMAGE === assetType ||
      ASSET_TYPE.LOGO === assetType ||
      ASSET_TYPE.HIDDEN === assetType ||
      ASSET_TYPE.PRODUCT === assetType ||
      ASSET_TYPE.BACKGROUND === assetType ||
      ASSET_TYPE.BACKGROUND_DISPLAY === assetType
    ) {
      return `${image.join(", ")} only*`;
    }
    if (ASSET_TYPE.FONT === assetType) {
      return `${font.join(", ")} only*`;
    }
    if (ASSET_TYPE.VIDEO === assetType) {
      return `${video.join(", ")} only*`;
    }
    if (ASSET_TYPE.GREEN_SCREEN === assetType) {
      return `${video.join(", ")} only*`;
    }
  };

  const getFileThumbnail = (assetType: ASSET_TYPE) => {
    if (ASSET_TYPE.GREEN_SCREEN === assetType) {
      return "txt only*";
    }
  };

  const onAdvanceOptionsUpdate = (param: string, value: any) => {
    setAdvanced(prev => ({
      ...prev,
      [param]: value,
    }));
  };
  const onCategoryChange = (e: any) => {
    const { value } = e.target;
    setBgCategory(value)
  }
  return (
    <>
      {!uploadAssetType && (
        <Grid
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-start"}
          rowGap={1}
          columnGap={1.5}
          width={"100%"}
          flexWrap={"wrap"}
        >
          {(isSuperAdmin ? SUPERADMIN_ASSET_TYPES : ASSET_TYPES).map((item, index) => {
            return (
              <Grid
                flexWrap={"wrap"}
                onClick={
                  modalMode === MODAL_MODE.UPLOAD
                    ? () => setAssetType(item)
                    : () => { }
                }
                key={index}
                sx={{
                  border: item === assetType ? "2px solid #3C88FC" : "2px solid #394455",
                  cursor:
                    modalMode === MODAL_MODE.UPLOAD || item === assetType
                      ? "pointer"
                      : "default",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  backgroundColor:
                    item === assetType ? "#3C88FC" : "transparent",
                  opacity:
                    modalMode === MODAL_MODE.UPLOAD || item === assetType
                      ? 1
                      : 0.3,
                  "&:hover": {
                    border: item === assetType ? "2px solid #3C88FC" : "2px solid #5C6674",
                    backgroundColor:
                      item === assetType ? "#3C88FC" : "transparent",
                    '.title_text': {
                      color: item === assetType ? 'white' : '#AEB6C4 !important'
                    }
                  },
                }}
              >
                <TypographyBodySmallNormal
                  className="title_text"
                  sx={{ color: item === assetType ? 'white' : "var(--color-palette-gray-500, #7D899C)" }}
                >
                  {enumToName(item)}
                </TypographyBodySmallNormal>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid
        display={"flex"}
        flexDirection={"column"}
        height={'calc(100% - 72px)'}
        width={"100%"}
        gap="24px"
        sx={{ overflow: 'auto', paddingBottom: '72px', paddingRight: '8px' }}
      >

        <Grid
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          onDragEnter={handleDrag}
          gap={2}
          onSubmit={(e) => e.preventDefault()}

        >
          {assetType === ASSET_TYPE.COLOR ? (
            <div
              className={`${styles["color__picker-container"]} color__picker-container`}
            >
              <HexColorPicker
                color={color}
                onChange={(value) => {
                  setColor(value);
                  (name.length === 0 || isColorValid(`#${name}`)) &&
                    setName(autofillName(value));
                }}
              />
            </div>
          ) : (
            <>
              {modalMode === MODAL_MODE.UPLOAD ? (
                <>
                  <Input
                    id="input-file-upload"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onFileChange(e, e.target.files, "asset")
                    }
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.5rem",
                      border: "1px solid #596573",
                      borderRadius: "1rem",
                      margin: "0",
                      cursor: "pointer",
                      opacity: dragActive ? 0.5 : 1,
                      background: dragActive ? "#394455" : "#253041",
                      padding: "1rem",
                    }}
                    className={`${styles["upload_label"]}`}
                  >
                    <div className={`${styles["asset-div"]}`}>{displayAsset}</div>
                    <div>
                      <TypographyBodyDefault
                        className={`${styles["upload__text"]}`}
                      >
                        {uploadText}
                      </TypographyBodyDefault>
                      <TypographyBodySmallNormal
                        className={`${styles["upload__sub_text"]} ${styles["upload__text"]}`}
                      >
                        {getFileTypeText(assetType)}
                      </TypographyBodySmallNormal>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      className={`${styles["dnd__area"]}`}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={(e) =>
                        onFileChange(e, e.dataTransfer.files, "asset")
                      }
                    ></div>
                  )}
                </>
              ) : (
                <>
                  <div className={styles["updateAsset-div"]}>{displayAsset}</div>
                </>
              )}
              {modalMode === MODAL_MODE.UPLOAD &&
                assetType === ASSET_TYPE.GREEN_SCREEN && (
                  <div
                    className={`${styles["dnd__container"]}`}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Input
                      id="coordinate-file-upload"
                      type="file"
                      className={`${styles["dnd__input"]}`}
                      sx={{ display: "none" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onFileChange(e, e.target.files, "coordinate")
                      }
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="coordinate-file-upload"
                      className={
                        dragActive
                          ? `${styles["dnd__label"]} ${styles["drag-active"]}`
                          : styles["dnd__label"]
                      }
                    >
                      <div className={`${styles["asset-div"]}`}>
                        {coordinateAssetDisplay}
                      </div>
                      <TypographyBodyDefault>
                        {coordinateFileUploadText}
                      </TypographyBodyDefault>
                      <TypographyBodySmallNormal
                        className={`${styles["upload__sub_text"]} ${styles["upload__text"]}`}
                      >
                        {getFileThumbnail(assetType)}
                      </TypographyBodySmallNormal>
                    </label>
                  </div>
                )}
              {modalMode === MODAL_MODE.UPLOAD &&
                THUMBNAIL_ASSET_TYPES.includes(assetType) && (
                  <div
                    className={`${styles["dnd__container"]}`}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Input
                      id="thumbnail-file-upload"
                      type="file"
                      className={`${styles["dnd__input"]}`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onFileChange(e, e.target.files, "thumbnail")
                      }
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="thumbnail-file-upload"
                      className={
                        dragActive
                          ? `${styles["dnd__label"]} ${styles["drag-active"]}`
                          : styles["dnd__label"]
                      }
                    >
                      <div className={`${styles["asset-div"]}`}>
                        {thumbnailAssetDisplay}
                      </div>
                      <TypographyBodyDefault>
                        {thumbnailFileUploadText}
                      </TypographyBodyDefault>
                      <TypographyBodySmallNormal
                        className={`${styles["upload__sub_text"]} ${styles["upload__text"]}`}
                      >
                        {getFileThumbnail(assetType)}
                      </TypographyBodySmallNormal>
                    </label>
                    {dragActive && (
                      <div
                        id="drag-file-element"
                        className={`${styles["dnd__area"]}`}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={(e) =>
                          onFileChange(e, e.dataTransfer.files, "thumbnail")
                        }
                      ></div>
                    )}
                  </div>
                )}
            </>
          )}
        </Grid>
        {/*** Color Hex Code Input ***/}
        {assetType === ASSET_TYPE.COLOR && (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            <div
              className={`${styles["color__preview"]}`}
              style={{ backgroundColor: color }}
            />
            <TextFieldStyled
              type="text"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
                (name.length === 0 || isColorValid(`#${name}`)) &&
                  setName(autofillName(e.target.value));
              }}
            />
          </Grid>
        )}
        {/*** Asset Name ***/}
        <Grid
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          width={"100%"}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"24px"}
            width={"100%"}
          >
            <TextFieldStyled
              placeholder="Asset Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

          </Grid>

          <Grid
            display={"flex"}
            gap={1.5}
          >
            {isSuperAdmin && !isBrandkit && <CheckboxStyled
              label="Public"
              checked={scopeType === SCOPE.GLOBAL}
              disabled={!currentBrandId}
              onChange={checked => {
                if (checked) {
                  setScopeType(SCOPE.GLOBAL);
                } else {
                  setScopeType(SCOPE.BRAND);
                }
              }}
            />}
            {assetType === ASSET_TYPE.PRODUCT && <CheckboxStyled
              label="Remove Background"
              checked={removeBackground}
              onChange={setRemoveBackground}
            />}
          </Grid>
        </Grid>
        {/*** Tags ***/}
        <Grid display={"flex"} width={"100%"}>
          <Autocomplete
            multiple
            freeSolo
            id="tags-readOnly"
            options={[]}
            value={tags}
            sx={{
              width: "100%",
              "& .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment .MuiButtonBase-root .MuiSvgIcon-root":
              {
                color: "white",
              },
            }}
            renderInput={({ inputProps, ...rest }: any) => {
              const { value, onChange, ...props } = inputProps;

              return (
                <TextFieldStyled
                  {...rest}
                  inputProps={props}
                  value={tag}
                  onChange={(e) => {
                    const value = e.target.value;
                    const lastChar = value?.charAt(value.length - 1);
                    if (lastChar.match(/^[a-zA-Z0-9_ ]+$/)) {
                      setTag(value);
                    } else {
                      if (value && tag.trim() !== "" && !tags.includes(tag.trim())) {
                        setTags([...tags, tag.trim()]);
                        setTag("");
                      }
                      setTag("");
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="tags"
                />
              );
            }}
            onChange={(e, value) => {
              setTags(value);
              setTag("");
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Chip
                  sx={{
                    backgroundColor: "#253042",
                    color: "#FFFFFF",
                    "& .MuiSvgIcon-root": {
                      color: "white !important",
                    },
                    "& .MuiChip-label": {
                      fontFamily: "Inter !important",
                      fontSize: "12px !important",
                      fontWeight: 500,
                      lineHeight: "20px !important",
                    },
                  }}
                  {...getTagProps({ index })}
                  label={option}
                />
              ));
            }}
          />
        </Grid>
        {/*** Product Metadata ***/}
        {assetType === ASSET_TYPE.PRODUCT && (
          <Grid
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            paddingTop={"1rem"}
            gap={2}
          >
            <TypographyBodyDefault>Asset Metadata</TypographyBodyDefault>
            <TextFieldStyled
              type="text"
              sx={{
                width: "100%",
              }}
              fullWidth
              value={productType}
              placeholder="Product Type"
              onChange={(e) => {
                setProductType(e.target.value);
              }}
            />
            <TextFieldStyled
              type="text"
              sx={{
                width: "100%",
              }}
              fullWidth
              value={description}
              placeholder="Product Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        )}
        {
          assetType === ASSET_TYPE.BACKGROUND && isSuperAdmin && (
            <SelectBgCategory option={bgCategoryOptions} value={bgCategory} onChange={onCategoryChange} />
          )
        }
        {/*** Background Display Metadata ***/}
        {assetType === ASSET_TYPE.BACKGROUND_DISPLAY && (
          <Grid
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            paddingTop={"1rem"}
            gap={2}
          >
            <TypographyBodyDefault>Asset Metadata</TypographyBodyDefault>
            <TextFieldStyled
              type="text"
              sx={{
                width: "100%",
              }}
              fullWidth
              value={prompt}
              placeholder="kept on sand, focus on product, forest in the background, detailed, reflections and shadows, "
              onChange={(e) => {
                setPrompt(e.target.value);
              }}
            />
            <SelectStyled
              value={backgroundAsset}
              onChange={(e: any) => {
                setBackgroundAsset(e.target.value);
              }}
              MenuProps={{
                sx: {
                  ".MuiPaper-root": {
                    bgcolor: "#1A202B",
                    overflow: "auto",
                    maxHeight: "500px",
                  },
                  ".MuiList-root": {
                    bgcolor: "#1A202B",
                    svg: {
                      fill: "#7D899C",
                    },
                  },
                  ".MuiMenuItem-root": {
                    bgcolor: "#1A202B",
                    color: "#7D899C",
                    ":hover": {
                      bgcolor: "var(--color-palette-gray-700, #394455)",
                    },
                  },
                },
              }}
            >
              {modalMode === MODAL_MODE.UPLOAD && <MenuItem key={0} value={"none"} sx={{ fontStyle: "italic" }}>
                {"Use current background"}
              </MenuItem>}
              {backgroundAssets.map((val) => {
                return (
                  <MenuItem key={val.id} value={val.id}>
                    {val.name}
                  </MenuItem>
                );
              })}
            </SelectStyled>
            {
              isSuperAdmin && <SelectBgCategory option={bgCategoryOptions} value={bgCategory} onChange={onCategoryChange} />
            }

            <Grid
              display={"flex"}
              width={"100%"}
              sx={{
                "div:first-of-type": {
                  rowGap: "1rem",
                  // columnGap: "1.5rem",
                },
              }}
            >
              {getAdvancedOptionsUI(advanced, onAdvanceOptionsUpdate)}
            </Grid>
          </Grid>
        )}

      </Grid>
      {/*** Footer ***/}
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        sx={{
          position: 'absolute', bottom: '0px', left: '0px', right: '0px', padding: '0px 32px 16px 32px',
          background: "var(--color-palette-gray-800, #253042)",
        }}
      >
        <TypographyBodySmallNormal
          sx={{ color: "var(--color-palette-gray-500, #7D899C)" }}
        >
          <span
            style={{
              fontWeight: "700",
              color: "var(--color-palette-gray-400, #AEB6C4)",
              letterSpacing: "0.15px",
            }}
          >
            {name ? name : "Upload file"}
          </span>
          {" of type "}
          <span
            style={{
              fontWeight: "700",
              color: "var(--color-palette-gray-400, #AEB6C4)",
              letterSpacing: "0.15px",
            }}
          >
            {enumToName(assetType).toUpperCase()}
          </span>
        </TypographyBodySmallNormal>
        <AsyncButton
          sx={{ p: "12px 24px", width: '153px', minWidth: '153px' }}
          text={modalMode === MODAL_MODE.UPLOAD ? "Upload" : "Update"}
          onClick={modalMode === MODAL_MODE.UPLOAD ? handleUpload : handleUpdate}
          onEnd={onClose}
        />
      </Grid>
    </>
  );
};

const AssetModal = (props: ModalProps) => {
  const { onClose, open, uploadAssetType, modalMode } = props;

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        sx: {
          background: "var(--color-palette-gray-800, #253042)",
          borderRadius: "16px",
          padding: "16px 32px",
          width: "856px",
          maxWidth: '856px',
          minHeight: "128px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1rem",
          height: '538px',
          position: 'relative'
        },
      }}
    >
      <Grid
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        gap={4}
        width={"100%"}
      >
        <TypographyBodyLarge
          sx={{
            color: "var(--color-palette-white, #FFF)",
            alignSelf: "center",
            textTransform: "capitalize",
          }}
        >
          {modalMode === MODAL_MODE.UPLOAD
            ? uploadAssetType
              ? `Upload ${startCase(toLower(uploadAssetType))}`
              : "Upload Asset"
            : "Update Asset"}
        </TypographyBodyLarge>
        <CloseIcon
          sx={{
            width: "32px",
            height: "32px",
            padding: "4px",
            color: "white",
            bgcolor: "#1A202B",
            borderRadius: "100px",
            cursor: "pointer",
            "&:hover": {
              bgcolor: '#394455'
            }
          }}
          onClick={() => onClose()}
        />
      </Grid>
      <AssetModalUI {...props} />
    </Dialog>
  );
};

export default AssetModal;
