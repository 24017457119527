import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi, putApi } from '../utils/fetchUtils';
import { AllOutputs, CallbackModelType, CallbackType, Output, showSnackbarType } from '../utils/types';
import { OUTPUT_STATUSES, PROJECT_TYPE, SEVERITY } from '../utils/enums';
import { AppThunk } from '../store';

const initialState = {
  allOutputs: null as AllOutputs | null,
  visibleOutputs: null as AllOutputs | null,
  imageOutputs: null as { [id: string]: Output[] } | null,
  otherOutputs: null as Output[] | null,
  mentionMembers:null as any,
  mentionMembersList:null as any
};

export const outputSlice = createSlice({
  name: "output",
  initialState,
  reducers: {


    updateMentionMembers:(state,{payload})=>{
return {...state,mentionMembers:payload}
    },
    listMentionMembers:(state,{payload})=>{
      
      return {...state,mentionMembersList:payload}
    },
    clearMentionMembers:(state,{payload})=>{
      return {...state,mentionMembersList:[]}

    },
    updateAllOutputs: (state, action: PayloadAction<Output[]>) => {
      state.allOutputs = {};
      action.payload.forEach(output => {
        if (!state.allOutputs) { state.allOutputs = {}; }
        state.allOutputs[output.id] = output;
      });
    },

    updateVisibleOutputs: (state, action: PayloadAction<Output[]>) => {
      state.visibleOutputs = {};
      action.payload.forEach(output => {
        if (!state.visibleOutputs) { state.visibleOutputs = {}; }
        state.visibleOutputs[output.id] = output;
      });
    },

    updateImageOutputs: (state, action: PayloadAction<Output[]>) => {
      state.imageOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.imageOutputs) { state.imageOutputs = {}; }

        if (!state.imageOutputs[output.projectId]) state.imageOutputs[output.projectId] = [output];
        else state.imageOutputs[output.projectId].push(output);
      });
    },

    updateOtherOutputs: (state, action: PayloadAction<Output[]>) => {
      state.otherOutputs = [];
      action.payload.forEach(output => {
        if (!state.otherOutputs) { state.otherOutputs = []; }

        state.otherOutputs.push(output);
      });
    },

    updateOutput: (state, action: PayloadAction<Output>) => {
      const output = action.payload;
      if (!state.allOutputs) { state.allOutputs = {}; }
      if (!state.visibleOutputs) { state.visibleOutputs = {}; }
      if (!state.imageOutputs) { state.imageOutputs = {}; }
      if (!state.otherOutputs) { state.otherOutputs = []; }

      state.allOutputs[output.id] = output;
      if (!OUTPUT_STATUSES.includes(output.status)) return;
      state.visibleOutputs[output.id] = output;

      if (output.projectId) {
        switch (output.type) {
          case PROJECT_TYPE.IMAGE:
          case PROJECT_TYPE.FACEBOOK_AD:
          case PROJECT_TYPE.IMAGE_EDITOR:
          case PROJECT_TYPE.IMAGE_GENERATION:
          case PROJECT_TYPE.IMAGE_SET:
            if (state.imageOutputs[output.projectId]) {
              if (state.imageOutputs[output.projectId].some(item => item.id === output.id)) {
                const index = state.imageOutputs[output.projectId].findIndex(item => item.id === output.id);
                state.imageOutputs[output.projectId][index] = output;
              } else {
                state.imageOutputs[output.projectId].push(output);
              }

            }
            else state.imageOutputs[output.projectId] = [output]
            break;

          default:
            break;
        }
      } else {
        state.otherOutputs.push(output);
      }
    },

    removeOutput: (state, action: PayloadAction<string>) => {
      if (!state.allOutputs) { state.allOutputs = {}; }
      if (!state.visibleOutputs) { state.visibleOutputs = {}; }
      if (!state.imageOutputs) { state.imageOutputs = {}; }
      if (!state.otherOutputs) { state.otherOutputs = []; }

      const output = state.allOutputs[action.payload];

      delete state.allOutputs[action.payload];
      delete state.visibleOutputs[action.payload];

      if (output?.projectId) {
        switch (output.type) {
          case PROJECT_TYPE.IMAGE:
          case PROJECT_TYPE.FACEBOOK_AD:
          case PROJECT_TYPE.IMAGE_EDITOR:
          case PROJECT_TYPE.IMAGE_GENERATION:
          case PROJECT_TYPE.IMAGE_SET:
            if (state.imageOutputs[output.projectId]) {
              state.imageOutputs[output.projectId] = state.imageOutputs[output.projectId].filter(val => val.id !== output.id);
            }
            break;
          default:
            break;
        }
      }
      else {
        state.otherOutputs = state.otherOutputs.filter(val => val.id !== output.id);
      }
    }
  },
});


export const {updateMentionMembers,listMentionMembers,clearMentionMembers}= outputSlice.actions

export default outputSlice.reducer;

export const getVisibleOutputs = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const params = new URLSearchParams();
    for (let status of OUTPUT_STATUSES) {
      params.append("statuses", status);
    }
    const visibleOutputs: Output[] = await getApi(`output?${params}`);
    dispatch(outputSlice.actions.updateAllOutputs(visibleOutputs));
    dispatch(outputSlice.actions.updateVisibleOutputs(visibleOutputs));
    dispatch(outputSlice.actions.updateImageOutputs(visibleOutputs.filter(output => [PROJECT_TYPE.IMAGE, PROJECT_TYPE.IMAGE_SET, PROJECT_TYPE.FACEBOOK_AD, PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.IMAGE_EDITOR].includes(output.type))));
    dispatch(outputSlice.actions.updateOtherOutputs(visibleOutputs.filter(output => !output.projectId)));
    callback?.();
    dispatch(getAllOutputs());
  }

export const getAllOutputs = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const allOutputs: Output[] = await getApi("output");
    dispatch(outputSlice.actions.updateAllOutputs(allOutputs));

    const visibleOutputs = allOutputs.filter(output => OUTPUT_STATUSES.includes(output.status));
    dispatch(outputSlice.actions.updateVisibleOutputs(visibleOutputs));
    dispatch(outputSlice.actions.updateImageOutputs(visibleOutputs.filter(output => [PROJECT_TYPE.IMAGE, PROJECT_TYPE.IMAGE_SET, PROJECT_TYPE.FACEBOOK_AD, PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.IMAGE_EDITOR].includes(output.type))));
    dispatch(outputSlice.actions.updateOtherOutputs(visibleOutputs.filter(output => !output.projectId)));
    callback?.();
  }

export const getOutputById = (id: string): AppThunk =>
  async dispatch => {
    const output: Output = await getApi(`output/${id}`);
    dispatch(outputSlice.actions.updateOutput(output));
  }

export const updateOutputById = (id: string, payload: any, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const output: Output = await postApi(`output/${id}`, payload);
    dispatch(outputSlice.actions.updateOutput(output));
    callback?.()
  }

export const deleteOutputs = (ids: string[], showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Outputs deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    for (const id of ids) {
      await deleteApi(`output/${id}`);
      dispatch(outputSlice.actions.removeOutput(id));
    }

    cb();
  }

export const uploadOutput = (payload: any, callback?: CallbackModelType<Output>): AppThunk =>
  async dispatch => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("mime_type", payload.mimeType);
    payload.projectId && formData.append("project_id", payload.projectId);
    payload.status && formData.append("status", payload.status);
    payload.key && formData.append("key", payload.key);
    payload.metadata && formData.append("metadata", JSON.stringify(payload.metadata));
    payload.scope && formData.append("scope", payload.scope);
    payload.type && formData.append("type", payload.type);

    const uploadedOutput: Output = payload.projectId ? await putApi("output", formData) : await postApi("output", formData);
    dispatch(outputSlice.actions.updateOutput(uploadedOutput));
    callback?.(uploadedOutput);
  }

export const uploadBulkOutputs = (payloads: any[], callback?: CallbackModelType<string[]>): AppThunk =>
  async dispatch => {
    const uploadedOutputs: string[] = [];
    for (const payload of payloads) {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("mime_type", payload.mimeType);
      payload.projectId && formData.append("project_id", payload.projectId);
      payload.status && formData.append("status", payload.status);
      payload.key && formData.append("key", payload.key);
      payload.metadata && formData.append("metadata", JSON.stringify(payload.metadata));
      payload.scope && formData.append("scope", payload.scope);
      payload.type && formData.append("type", payload.type);

      const uploadedOutput: Output = payload.projectId ? await putApi("output", formData) : await postApi("output", formData);
      uploadedOutputs.push(uploadedOutput.id);
      dispatch(outputSlice.actions.updateOutput(uploadedOutput));
    }
    callback?.(uploadedOutputs);
  }
