import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import { loadGlobalFont } from "store/asset";
import { deleteApi, getApi, postApi } from "utils/fetchUtils";
import { CallbackModelType, CallbackType, PropertyData } from "utils/types";

const initialState = {
  userProperties: null as PropertyData[] | null,
  brandProperties: null as PropertyData[] | null,
  onboardingFlow: false
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    updateUserProperties: (state, action: PayloadAction<PropertyData[]>) => {
      state.userProperties = action.payload;
    },
    updateBrandProperties: (state, action: PayloadAction<PropertyData[]>) => {
      state.brandProperties = action.payload;
    },
  },
});

export default propertySlice.reducer;

///// USER /////

export const getUserProperties = (): AppThunk =>
  async (dispatch, getState) => {
    const userId = getState().user.session?.userId ?? "";
    const response = await getApi(`property/USER/${userId}`);
    dispatch(propertySlice.actions.updateUserProperties(response));
  }

export const createUserProperty = (key: string, value: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const userId = getState().user.session?.userId ?? "";
    await postApi("property", { modelType: "USER", modelId: userId, key, value });
    dispatch(getUserProperties())
    callback?.();
  }

export const updateUserProperty = (key: string, value: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const userId = getState().user.session?.userId ?? "";
    await postApi(`property/USER/${userId}/${key}`, { value });
    dispatch(getUserProperties())
    callback?.();
  }

export const deleteUserProperty = (key: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const userId = getState().user.session?.userId ?? "";
    await deleteApi(`property/USER/${userId}/${key}`);
    dispatch(getUserProperties())
    callback?.();
  }

///// BRAND /////

export const getBrandProperties = (brandId?: string, callback?: CallbackModelType<PropertyData[]>): AppThunk =>
  async (dispatch, getState) => {
    const finalBrandId = brandId ?? getState().user.session?.brandId ?? "";
    const response: PropertyData[] = await getApi(`property/BRAND/${finalBrandId}`);
    if (!brandId) {
      dispatch(propertySlice.actions.updateBrandProperties(response));
    }

    const templateFonts = response.find(item => item.key === "templateFonts");
    if (templateFonts) {
      await Promise.all((JSON.parse(templateFonts.value) as any[]).map(font => loadGlobalFont(font.fontFamily, font.url)));
    }
    callback?.(response);
  }

export const createBrandProperty = (key: string, value: string, brandId?: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const finalBrandId = brandId ?? getState().user.session?.brandId ?? "";
    await postApi("property", { modelType: "BRAND", modelId: finalBrandId, key, value });
    dispatch(getBrandProperties(brandId));
    callback?.();
  }

export const updateBrandProperty = (key: string, value: string, brandId?: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const finalBrandId = brandId ?? getState().user.session?.brandId ?? "";
    await postApi(`property/BRAND/${finalBrandId}/${key}`, { value });
    dispatch(getBrandProperties(brandId));
    callback?.();
  }

export const deleteBrandProperty = (key: string, brandId?: string, callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const finalBrandId = brandId ?? getState().user.session?.brandId ?? "";
    await deleteApi(`property/BRAND/${finalBrandId}/${key}`);
    dispatch(getBrandProperties(brandId));
    callback?.();
  }
