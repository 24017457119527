import JSZip from "jszip";
import { v4 } from "uuid";
import { getApi } from "./fetchUtils";
import { Asset, Output, Text, showSnackbarType } from "./types";
import { capitalize } from "@mui/material";
import { EXPORT_OPTIONS, PROJECT_TYPE, ROUTE_PATH_MAP, SEVERITY } from "./enums";
import axios from "axios";

type Position = "absolute";

export const getUniqueId = v4;

export const getTextStyle = (text: Text) => ({
    cursor: "pointer",
    position: "absolute" as Position,
    margin: "0px",

    fontFamily: text.fontFamily,
    fontCase: text.fontCase,
    color: text.color,
    fontSize: text.fontSize + "px",
    fontWeight: text.bold ? "bold" : "normal",
    textDecorationLine: text.underline ? "underline" : "none",
    fontStyle: text.italic ? "italic" : "normal",
    opacity: text.opacity / 100,
})

export const nameToSlug = (name: string) => name.toLowerCase()
    .replace(/ /g, "-")
    .replace(/_/g, "-")
    .replace(/[^\w-]+/g, "");

export const slugToName = (slug?: string | null) => slug?.toLowerCase()
    .split("-")
    .map(capitalize)
    .join(" ") ?? "";

export const slugToEnum = (slug?: string | null) => slug?.toLowerCase()
    .split("-")
    .map(word => word.toLocaleUpperCase())
    .join("_");

export const enumToName = (slug: string) => slug.toLowerCase()
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .split(" ")
    .map(capitalize)
    .join(" ") ?? "";


function getTextBlob(text: string, type?: EXPORT_OPTIONS) {
    if (type === EXPORT_OPTIONS.CSV) {
        return new Blob([text], { type: 'text/csv;charset=utf-8;' });
    }
    return new Blob([text], { type: 'text/plain;charset=utf-8;' });
}

export const downloadFilesFromIds = (ids: string[], type: string, showSnackbar?: showSnackbarType) => Promise.all(ids.map(id => getApi(`${type}/${id}`))).then(files => downloadFiles(files, showSnackbar));

export function downloadFiles(files: Asset[] | Output[], showSnackbar?: showSnackbarType, text?: string, textType?: EXPORT_OPTIONS) {

    if (files.length === 1 && !text) {
        return axios.get(files[0].url, {
            responseType: "blob",
            headers: {
                "Cache-Control": "no-cache"
            }
        })
            .then((res) => new Blob([res.data]))
            .then(blob => {
                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.download = files[0].fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error(error);
                showSnackbar?.(String(error), SEVERITY.ERROR);
            });
    }

    const zip = new JSZip();
    if (text && textType) {
        const blob = getTextBlob(text, textType);
        zip.file(`texts${textType === EXPORT_OPTIONS.CSV ? ".csv" : ".txt"}`, blob);
    }

    return Promise.all(files.map(async (file: Asset | Output) => {
        const response = await axios.get(file.url, {
            responseType: "blob",
            headers: {
                "Cache-Control": "no-cache"
            }
        })
        const blob = new Blob([response.data]);
        zip.file(file.fileName, blob);
    }))
        .then(() => zip.generateAsync({ type: "blob" }))
        .then((zipBlob) => {
            const objectUrl = window.URL.createObjectURL(zipBlob);
            const a = document.createElement("a");
            a.href = objectUrl;
            a.download = "files.zip";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            console.error(error);
            showSnackbar?.(String(error), SEVERITY.ERROR);
        });
};

export function downloadTextFile(text: string, type?: EXPORT_OPTIONS, filename?: string) {
    const blob = getTextBlob(text, type)
    const url = URL.createObjectURL(blob);;
    var downloadEl = document.createElement('a');
    downloadEl.href = url;
    document.body.appendChild(downloadEl);
    downloadEl.setAttribute("download", (filename ?? "texts") + type === EXPORT_OPTIONS.CSV ? ".csv" : ".txt");
    downloadEl.click();
    document.body.removeChild(downloadEl);
}

export function getRandomElementFromArray<T>(array: T[]) {
    if (array.length === 0) { return undefined; }

    const index = Math.floor(Math.random() * array.length);
    return array[index];
}

export function getProjectUrl(projectId: string, type: PROJECT_TYPE) {
    switch (type) {
        case PROJECT_TYPE.IMAGE:
            return `${ROUTE_PATH_MAP.IMAGE_EDITOR}/${projectId}`;
        case PROJECT_TYPE.FACEBOOK_AD:
            return `${ROUTE_PATH_MAP.FACEBOOK_ADS}/${projectId}`;
        case PROJECT_TYPE.IMAGE_GENERATION:
            return `${ROUTE_PATH_MAP.IMAGE_GENERATOR}/${projectId}`;
        case PROJECT_TYPE.TEXT_GENERATION:
            return `${ROUTE_PATH_MAP.TEXT_COPY_GENERATOR}/${projectId}`;
        default:
            return "#";
    }
};

export function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}

export function copyToClipboard(text: string, showSnackbar: showSnackbarType, object?: string) {
    navigator.clipboard.writeText(text);
    showSnackbar(`${object ? `${object} c` : "C"}opied to clipboard`);
};

export function dataURLtoBlob(dataurl: any) {
    console.log(dataurl);
    if (!dataurl) {
        console.error("empty data url");
        return new Blob([], { type: "image/png" });
    }
    // console.log(dataurl);
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export const getRandomNumber = (min: number, max: number) => Math.floor(min + Math.random() * (max - min));

export const validateEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(regex);
}

export const downloadFileFromURL = async (fileURL: string, fileName: string, showSnackbar?: showSnackbarType) => {
    try {
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        showSnackbar?.(String(error), SEVERITY.ERROR);
    }

}