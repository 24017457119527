

///// ROUTING /////


export enum ROUTE_PATH_MAP {
  "HOME" = "/",
  "CREATE" = "/create",
  "ASSETS" = "/assets",
  "RENDERS" = "/renders",
  "FOLDERS" = "/folders",
  "USERS" = "/users",
  "BRANDS" = "/brands",
  "HELP" = "/help",
  "CONTACT_US" = "/help/tickets",

  "TEXT_COPY_GENERATOR" = "/create/ad-copy",
  "IMAGE_GENERATOR" = "/create/image-gen",
  "FACEBOOK_ADS" = "/create/fb-ads",

  "IMAGE_EDITOR" = "/editor",

  // auth routes
  "AUTH" = "/auth/*",
  "AUTH_SIGNUP" = "/auth/signup",
  "AUTH_EMAIL_CONFIRMATION" = "/auth/email-confirmation",
  "AUTH_EMAIL_VERIFICATION" = "/auth/email-verification",
  "AUTH_ENTER_PASSWORD" = "/auth/enter-password",
  "AUTH_BRAND_INVITE" = "/auth/brand-invite",
  "AUTH_LOGOUT" = "/auth/logout",

  "NOT_FOUND" = "/404",
  "NOTIFICATION_TABLE" = '/notification'
}
export const ROUTE_PATHS = Object.values(ROUTE_PATH_MAP);


///// AUTH /////


export enum TOKEN_SOURCE {
  "AUTH0" = "AUTH0",
  "LOGIN" = "LOGIN",
  "SESSION" = "SESSION",
};

export enum SESSION_TYPE {
  "USER" = "USER",
  "EXTERNAL" = "EXTERNAL",
};

export enum USER_ROLE {
  "ca-brand-admin" = "ca-brand-admin",
  "ca-brand-editor" = "ca-brand-editor",
};
export const USER_ROLES = Object.values(USER_ROLE);

export enum INVITE_STATUS {
  "PENDING" = "PENDING",
  "EXPIRED" = "EXPIRED",
  "SIGNUP" = "SIGNUP",
  "VERIFIED" = "VERIFIED",
  "COMPLETED" = "COMPLETED",
};

export const USER_LOGOUT = "USER_LOGOUT";


///// MODEL /////

export enum MODEL_NAMES {
  ASSET = "asset",
  BRANDKIT = "brandkit",
  FOLDER = "folder",
  PROJECT = "project",
  OUTPUT = "output",
  TEMPLATE = "template",
  INVITE = "invite",
  USER = "user",
};

export enum PERMISSIONS {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export enum SCOPE {
  "BRAND" = "BRAND",
  "PRIVATE" = "PRIVATE",
  "GLOBAL" = "GLOBAL",
};
export const SCOPES = Object.values(SCOPE);

export enum ASSET_TYPE {
  "VIDEO" = "VIDEO",
  "IMAGE" = "IMAGE",
  "LOGO" = "LOGO",
  "COLOR" = "COLOR",
  "FONT" = "FONT",
  "GREEN_SCREEN" = "GREEN_SCREEN",
  "HIDDEN" = "HIDDEN",
  "PRODUCT" = "PRODUCT",
  "BACKGROUND" = "BACKGROUND",
  "BACKGROUND_DISPLAY" = "BACKGROUND_DISPLAY",
  "PDF" = "PDF",

  // frontend only
  "TEXT" = "TEXT",
  "LIBRARY" = "LIBRARY",
  "BRANDKIT" = "BRANDKIT",
  "TEMPLATE" = "TEMPLATE",
};

export const ASSET_TYPES = [ASSET_TYPE.IMAGE, ASSET_TYPE.PRODUCT, ASSET_TYPE.BACKGROUND, ASSET_TYPE.BACKGROUND_DISPLAY, ASSET_TYPE.VIDEO];
export const SUPERADMIN_ASSET_TYPES = [...ASSET_TYPES, ASSET_TYPE.GREEN_SCREEN, ASSET_TYPE.HIDDEN];
export const ALL_ASSET_TYPES = Object.values(ASSET_TYPE);

export enum PROJECT_TYPE {
  "EMPTY" = "EMPTY",
  "IMAGE_EDITOR" = "IMAGE_EDITOR",
  "IMAGE" = "IMAGE",
  "TEXT_GENERATION" = "TEXT_GENERATION",
  "IMAGE_GENERATION" = "IMAGE_GENERATION",
  "FACEBOOK_AD" = "FACEBOOK_AD",
  "IMAGE_SET" = "IMAGE_SET",
};
export const ALL_PROJECT_TYPES = Object.values(PROJECT_TYPE);

export enum TASK_TYPE {
  "CREATIVE_DIFFUSION" = "CREATIVE_DIFFUSION",
};

export enum TASK_STATUS {
  "QUEUED" = "QUEUED",
  "RUNNING" = "RUNNING",
  "COMPLETED" = "COMPLETED",
  "ERROR" = "ERROR",
};

export enum OUTPUT_STATUS {
  "NULL" = "NULL",
  "VISIBLE" = "VISIBLE",
  "PROCESSING" = "PROCESSING",
  "APPROVED" = "APPROVED",
  "REJECTED" = "REJECTED",
};
export const OUTPUT_STATUSES = [OUTPUT_STATUS.VISIBLE, OUTPUT_STATUS.PROCESSING, OUTPUT_STATUS.APPROVED, OUTPUT_STATUS.REJECTED];

///// IMAGE EDITOR /////


export enum ActiveObjectType {
  Rect = "rect",
  Image = "image",
  Text = "textbox",
  Group = "group",
}

export enum RIGHT_MENU_OPTION {
  "AI_MAGIC" = "AI_MAGIC",
  "TEXT" = "TEXT",
  "ELEMENTS" = "ELEMENTS",
  "LAYERS" = "LAYERS",
  "TEMPLATE" = "TEMPLATE",
  "INSERT" = "INSERT",
  "TEXT_PROPS" = "TEXT_PROPS",
  "ELEMENT_PROPS" = "ELEMENT_PROPS",
  "IMAGE" = "IMAGE",
  "IMAGE_PROPS" = "IMAGE_PROPS",
  "NULL" = "EMPTY"
}

export enum AI_MAGIC_OPTION {
  "GENERATE" = "GENERATE",
  "MAGIC_ERASER" = "MAGIC_ERASER",
  "MAGIC_SWAP" = "MAGIC_SWAP",
  "SEGMENT" = "SEGMENT",
  "UPSCALE" = "UPSCALE",
  "REMOVE_BG" = "REMOVE_BG",
  "NULL" = "NULL"
}

///// CUSTOM /////


export enum EXPORT_OPTIONS {
  "TXT" = "TXT",
  "CSV" = "CSV",
}

export enum MODAL_MODE {
  "EDITING" = "EDITING",
  "DISPLAY" = "DISPLAY",
  "UPLOAD" = "UPLOAD",
};

export enum SEVERITY {
  "ERROR" = "error",
  "WARNING" = "warning",
  "INFO" = "info",
  "SUCCESS" = "success",
}

export enum MAGIC_ACTION {
  "ADD_MASK" = "ADD_MASK",
  "REMOVE_MASK" = "REMOVE_MASK",
  "DRAW_MASK" = "DRAW_MASK",
};
export enum JOYRIDE_TYPE {
  "INITIAL_USER_GUIDE" = "INITIAL_USER_GUIDE",
  "USER_GUIDE" = "USER_GUIDE"

}
export enum USER_GUIDE_STEP {
  "CARD" = "CARD",
  "PROMPT" = "PROMPT",
  "SELECT_PRODUCT" = "SELECT_PRODUCT",
  "SELECT_PRODUCT_BACKGROUND" = "SELECT_PRODUCT_BACKGROUND",
  "SELECT_PRODUCT_PLACER" = "SELECT_PRODUCT_PLACER",
  "SELECT_PROMPT_GENERATE" = "SELECT_PROMPT_GENERATE",
  "BOOKMARK_IMAGE" = "BOOKMARK_IMAGE",
  "CREATE_FOLDER" = "CREATE_FOLDER",
  "GO_TO_EDITOR" = "GO_TO_EDITOR",
  "IMAGE_EDITOR_ADAPT" = "IMAGE_EDITOR_ADAPT",
  "IMAGE_EDITOR_AI" = "IMAGE_EDITOR_AI",
  "SELECT_EXPORT_AD" = "SELECT_EXPORT_AD",
  "EXPORT_DOWNLOAD" = "EXPORT_DOWNLOAD",
  "HOME" = "HOME",
  "ASSETS" = "ASSETS",
  "ASSETS_DETAILS" = "ASSETS_DETAILS",
  "END" = "END"
};



/////Notification//////

export enum NOTIFICATION_ACTION {
  ASSET_UPLOAD = "ASSET_UPLOAD",//Check in asset type and open /assets if it inclueds in [ASSET_TYPE.LOGO, ASSET_TYPE.COLOR, ASSET_TYPE.FONT] onpen second else first
  USER_LEVEL_UPDATE = "USER_LEVEL_UPDATE",
  NEW_USER_ADD = "NEW_USER_ADD",//only home
  USER_WELCOME = "USER_WELCOME",// home and open profile
  RENDER_ADMIN_APPROVAL = "RENDER_ADMIN_APPROVAL",//open render model but tab will be detail mean 0
  RENDER_EDITOR_APPROVAL = "RENDER_EDITOR_APPROVAL",//open render model but tab will be detail mean 0

  USER_CHAT_NOTIFICATION = "USER_CHAT_NOTIFICATION",//open render model but tab will be detail mean 1
  PROJECT_CREATOR_CHAT_NOTIFICATION = "PROJECT_CREATOR_CHAT_NOTIFICATION",//open render model but tab will be detail mean 1

  TICKET_CREATED = "TICKET_CREATED",/// open /contact-us
  TICKET_ESCALATION = "TICKET_ESCALATION",/// open /contact-us

}

export const notificationRedirect: any = {
  ASSET_UPLOAD: { isNeedToredirect: true, path: "/assets", isModelOpen: true, tabNumber: 0 },//Check in asset type and open /assets if it inclueds in [ASSET_TYPE.LOGO, ASSET_TYPE.COLOR, ASSET_TYPE.FONT] onpen second else first
  USER_LEVEL_UPDATE: { isNeedToredirect: false },
  NEW_USER_ADD: { isNeedToredirect: true, path: "/", isModelOpen: false },//only home
  USER_WELCOME: { isNeedToredirect: true, path: "/", isModelOpen: true, },// home and open profile
  RENDER_ADMIN_APPROVAL: { isNeedToredirect: true, path: "/renders", isModelOpen: true, tabNumber: 0 },//open render model but tab will be detail mean 0
  RENDER_EDITOR_APPROVAL: { isNeedToredirect: true, path: "/renders", isModelOpen: true, tabNumber: 0 },//open render model but tab will be detail mean 0
  USER_CHAT_NOTIFICATION: { isNeedToredirect: true, path: "/renders", isModelOpen: true, tabNumber: 1 },//open render model but tab will be detail mean 1
  PROJECT_CREATOR_CHAT_NOTIFICATION: { isNeedToredirect: true, path: "/renders", isModelOpen: true, tabNumber: 1 },//open render model but tab will be detail mean 1

  TICKET_CREATED: { isNeedToredirect: true, path: "/contact-us", isModelOpen: false, },/// open /contact-us
  TICKET_ESCALATION: { isNeedToredirect: true, path: "/contact-us", isModelOpen: false, },/// open /contact-us
}
export enum BG_CATEGORY {
  "AESTHETICS" = "AESTHETICS",
  "INTERIOR" = "INTERIOR",
  "MINIMALISTIC"="MINIMALISTIC",
  "BOOKS" = "BOOKS",
  "NATURAL" = "NATURAL",
  "PODIUM" = "PODIUM",
  "PREMIUM" = "PREMIUM",
  "UPLOADED" = "UPLOADED",
  "GENERATED" = "GENERATED"
}

export const BG_CATEGORY_OPTIONS_SUPER_ADMIN = [BG_CATEGORY.AESTHETICS,BG_CATEGORY.MINIMALISTIC, BG_CATEGORY.INTERIOR, BG_CATEGORY.BOOKS, BG_CATEGORY.NATURAL, BG_CATEGORY.PODIUM, BG_CATEGORY.PREMIUM, BG_CATEGORY.UPLOADED];
export const BG_CATEGORY_OPTIONS = [BG_CATEGORY.UPLOADED];
