import { Grid, Icon, Tooltip } from "@mui/material";
import {
  TypographyBodyDefault,
  TypographyBodyLarge,
  TypographyBodyMedium,
} from "components/styledComponents/Typography";
import React, { useState } from "react";
import { ASSET_TYPE, MODAL_MODE, MODEL_NAMES, PERMISSIONS } from "utils/enums";
import { hasRolePermission } from "utils/session";
import { UploadIcon } from "components/Icons";
import BrandGuidelineModal from "components/modals/BrandGuidelineModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useAppSelector } from "hooks/store";
import { useEventsProvider } from "mixPanelEvent";

const BrandGuideline = () => {
  const canCreate = hasRolePermission(MODEL_NAMES.BRANDKIT, PERMISSIONS.CREATE);
  const canDelete = hasRolePermission(MODEL_NAMES.BRANDKIT, PERMISSIONS.DELETE);

  const [openBrandGuideline, setOpenBrandGuideline] = useState(false);
  const pdfAssets = useAppSelector((store) =>
    store.asset.brandkitAssets?.filter((asset) => asset.type === ASSET_TYPE.PDF)
  );

  const [currentGuideline, setCurrentGuideline] = useState<{
    url: string;
    name: string;
  }>();

  return (
    <>
      {openBrandGuideline && (
        <BrandGuidelineModal
          currentGuideline={currentGuideline}
          open={openBrandGuideline}
          modalMode={
            currentGuideline?.url ? MODAL_MODE.DISPLAY : MODAL_MODE.UPLOAD
          }
          onClose={() => {
            setCurrentGuideline(undefined);
            setOpenBrandGuideline(false);
          }}
        />
      )}
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        width={"100%"}
        gap={2}
      >
        <TypographyBodyLarge sx={{ color: "var(--color-palette-white, #FFF)" }}>
          Brand Guidelines
        </TypographyBodyLarge>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={4}
          flexWrap={"wrap"}
        >
          {pdfAssets &&
            pdfAssets.map((asset) => (
              <Grid
                key={asset.id}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"6px"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentGuideline({ url: asset.url, name: asset.name });
                  setOpenBrandGuideline(true);
                }}
              >
                <PictureAsPdfIcon
                  sx={{
                    fontSize: "4rem",
                    color: "var(--color-palette-gray-500, #7D899C)",
                    marginBttom: "4px",
                  }}
                />
                <TypographyBodyDefault>{asset.name}</TypographyBodyDefault>
              </Grid>
            ))}
          {canDelete && canCreate && (
            <Tooltip title="Add Brand Guideline">
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"6px"}
                id="brandGuidelines"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenBrandGuideline(true);
                }}
              >
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    padding: "20px 34px",
                    borderRadius: "16px",
                    border: "1px dashed var(--color-palette-gray-700, #394455)",
                    gap: "6px",
                  }}
                >
                  <Icon>{UploadIcon}</Icon>
                  <TypographyBodyMedium
                    sx={{ color: "var(--color-palette-white, #FFF)" }}
                  >
                    Add Brand Guideline
                  </TypographyBodyMedium>
                </Grid>
              </Grid>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(BrandGuideline);
